<template>
  <el-main>
    <el-button style="margin-bottom: 10px" @click="add" size="small" type="primary" icon="el-icon-plus">添加分销员</el-button>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="分销员：">
        <el-input size="small" placeholder="请输入昵称" v-model="searchForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input size="small" placeholder="请输入手机号" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="分销员等级：">
        <el-select v-model="searchForm.level_id" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="item in gradeList" :key="item.level_id" :label="item.name" :value="item.level_id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="来源：">
        <el-select v-model="searchForm.comefrom" size="small">
          <el-option v-for="item in comefrom" :key="item.val" :label="item.label" :value="item.val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="加入时间：">
        <el-date-picker
          value-format="timestamp"
          @change="getTime"
          v-model="searchForm.time"
          size="small"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="分销员信息" width="220" align="center">
        <template v-slot="{ row }">
          <div class="userInfo">
            <el-image :src="row.avatar"></el-image>
            <span>{{ row.nickname || '--' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="用户账号" width="120" align="center"></el-table-column>
      <el-table-column prop="invite_code" label="邀请码" align="center"></el-table-column>
      <el-table-column prop="level_name" label="分销员等级" width="100" align="center"></el-table-column>
      <el-table-column label="邀请方昵称" width="100" align="center">
        <template v-slot="{ row }">
          {{ row.parent_id ? row.parent_nickname : '总公司' }}
        </template>
      </el-table-column>
      <el-table-column label="邀请方手机号" width="140" align="center">
        <template v-slot="{ row }">
          {{ row.parent_id ? row.parent_mobile : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="累计积分收入" width="140" align="center">
        <template v-slot="{ row }">
          {{ row.integral_user ? row.integral_user.income_integral_amount || '--' : '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="sales_amount" label="累计销售金额" width="140" align="center"></el-table-column>
      <!-- <el-table-column prop="commission_amount" label="累计佣金" align="center"></el-table-column> -->
      <el-table-column prop="integral_amount" label="种子金额" align="center"></el-table-column>
      <el-table-column prop="invite_count" label="累计邀请分销员数" width="140" align="center"></el-table-column>
      <el-table-column prop="integral_user.real_integral_amount" label="实际兑换积分" width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="!row.integral_user">0.00</div>
          <div v-else>{{ row.integral_user.real_integral_amount || '0.00' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="integral_user.integral_amount" label="已释放兑换积分" width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="!row.integral_user">0.00</div>
          <div v-else>{{ row.integral_user.integral_amount || '0.00' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="integral_user.nft_integral_amount" label="nft积分" width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="!row.integral_user">0.00</div>
          <div v-else>{{ row.integral_user.nft_integral_amount || '0.00' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="integral_user.withdraw_amount" label="待释放积分" width="100" align="center">
        <template v-slot="{ row }">
          <div v-if="!row.integral_user">0.00</div>
          <div v-else>{{ row.integral_user.withdraw_amount || '0.00' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="from_icon" label="来源" align="center">
        <template v-slot="{ row }">
          <el-image :src="row.from_icon"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.status > 0 ? '已清退' : '正常' }}
        </template>
      </el-table-column>
      <el-table-column prop="date" label="注册时间" width="150" align="center"></el-table-column>
      <el-table-column label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.type == 4" type="text" size="small" @click="openChangeDialog(scope.row, 1)">转账</el-button>
          <el-button v-if="scope.row.type == 4" type="text" size="small" @click="openChangeDialog(scope.row, 2)">转账释放积分</el-button>
          <el-button v-if="scope.row.type == 4" type="text" size="small" @click="openDetailsDialog(1)">转账明细</el-button>
          <el-button v-if="scope.row.type != 3 && scope.row.type != 4" type="text" size="small" @click="openDetailsDialog(3, scope.row)">转账业绩明细</el-button>
          <el-button v-if="scope.row.type == 4" type="text" size="small" @click="openDetailsDialog(2)">释放积分转账明细</el-button>
          <el-button v-if="scope.row.type != 3 && scope.row.type != 4" @click="transferAccountsOpenDialog(scope.row)" type="text" size="small">转账业绩</el-button>
          <el-button @click="lookAchievementDetails(scope.row)" type="text" size="small">查看业绩明细</el-button>
          <el-button @click="seeDetail(scope.row)" type="text" size="small">查看详情</el-button>
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="out(scope.row)" type="text" size="small">清退</el-button>
          <el-button v-if="scope.row.type != 3 && scope.row.type != 4" @click="association(scope.row)" type="text" size="small">关联父级账号</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加/编辑分销员" :visible.sync="dialogAdd" width="650px">
      <el-form label-width="120px">
        <el-form-item label="用户来源：">
          <el-select v-model="addForm.comefrom" :disabled="addForm.id">
            <el-option v-for="item in comefrom.slice(1, 6)" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用户：">
          <div class="add-img">
            <template v-if="addForm.userImg">
              <div class="img" @mouseover="showRmove = !0" @mouseout="showRmove = !1">
                <el-image :src="addForm.userImg" :fit="scale - down"></el-image>
                <span v-if="!addForm.id" v-show="showRmove" class="remove">
                  <span class="item-delete" @click.stop="removeInfo"><i class="el-icon-delete"></i></span>
                </span>
              </div>
            </template>
            <div v-else :class="['select', showBorder ? 'select-active' : '']" @click="showSelectUser = !0" @mouseover="showBorder = !0" @mouseout="showBorder = !1">
              <i class="el-icon-plus"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="手机号：">
          <el-input v-model="addForm.mobile" disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称：">
          <el-input v-model="addForm.nickname" disabled></el-input>
        </el-form-item>
        <el-form-item label="邀请人：">
          <el-select v-model="addForm.parent_id" :disabled="addForm.id" clearable filterable>
            <el-option v-for="item in totalList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分销等级：">
          <el-select v-model="addForm.level_id" size="small">
            <el-option v-for="item in gradeList" :key="item.level_id" :label="item.name" :value="item.level_id"></el-option>
          </el-select>
          <el-button v-show="!gradeList.length" @click="goSetGrade" style="margin-left: 15px" type="text">暂无等级，请去设置</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAdd = !1">取 消</el-button>
        <el-button type="primary" @click="comefromAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="清退" :visible.sync="dialogOut" width="650px">
      <el-form label-width="120px">
        <el-form-item style="margin: 0" label="你正在清退">
          <p class="out-info">
            <span>{{ outForm.nickname }}</span>
            <span>{{ outForm.mobile }}</span>
            <span>{{ outForm.level_name }}</span>
          </p>
          <p>清退后推广金额会清零</p>
          <p>分销等级会清空</p>
        </el-form-item>
        <el-form-item style="margin: 0" label="客户关系和下级处理为：" label-width="180px"></el-form-item>
        <el-form-item>
          <el-radio-group v-model="outForm.status">
            <el-radio style="margin-bottom: 15px" :label="1">不处理，客户管理保留，但被清退用户不享受分享佣金或分红</el-radio>
            <el-radio style="margin-bottom: 15px" :label="2">解除被清退客户和下级的绑定管理，其下级自动归属为被清退客户的上级</el-radio>
            <el-radio style="margin-bottom: 15px" :label="3">被清退客户的下级指定给某一个分销员</el-radio>
          </el-radio-group>
          <el-select v-if="outForm.status == 3" v-model="outForm.distribution_id" clearable filterable>
            <el-option v-for="item in totalList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogOut = !1">取 消</el-button>
        <el-button type="primary" @click="comefromOut">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择用户" :visible.sync="showSelectUser" width="52%">
      <ListComponents :styleMethon="6" :comefrom="addForm.comefrom" v-if="showSelectUser" @getListItem="getItem"></ListComponents>
    </el-dialog>
    <!-- 转账弹窗 -->
    <el-dialog :title="changeMode == 1 ? '转账' : '转账释放积分'" :visible.sync="showChangeDialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="手机号：">
          <el-select v-more="loadMoreType" v-model="changeTel" filterable remote :remote-method="getChangeList" placeholder="请选择" class="classify_select" @change="fn">
            <el-option v-for="item in change_info.list" :key="item.id" :label="item.mobile" :value="item.mobile"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额：">
          <el-input v-model="change_info.amount" placeholder="请输入转账金额"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showChangeDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转账明细 -->
    <el-dialog :visible.sync="change_details_info_dialog" width="780px">
      <template #title>
        <div>
          <span>{{ details_dialog_info[`${changeMode}`].title }}</span>
          <el-button style="margin-left: 12px" v-if="changeMode == 1 || changeMode == 3" size="small" @click="exportFn">导出</el-button>
        </div>
      </template>
      <el-table :data="change_details_info.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column align="center" label="手机号">
          <template v-slot="{ row }">{{ row.mobile }}</template>
        </el-table-column>
        <el-table-column label="用户昵称">
          <template v-slot="{ row }">{{ row.distribution_info.nickname || '--' }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column label="金额" align="center">
          <template v-slot="{ row }">{{ row.income_type == -1 ? '-' : '+' }}{{ row[`${details_dialog_info[`${changeMode}`].money}`] }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="change_details_info.total" :page="change_details_info.page" :pageNum="change_details_info.rows" @updatePageNum="detailsUpdateData"></Paging>
    </el-dialog>
    <!-- 关联父级弹窗 -->
    <el-dialog title="关联父级账号" :visible.sync="parent_dialog" width="600rpx">
      <el-form label-width="120px" class="demo-ruleForm">
        <el-form-item label="父级账号：">
          <el-select v-more="loadMoreType" v-model="parent_id" filterable remote :remote-method="getChangeList" placeholder="请选择" class="classify_select" @change="fn">
            <el-option v-for="item in change_info.list" :key="item.id" :label="item.mobile" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="parent_dialog = false">取 消</el-button>
        <el-button type="primary" @click="sureAssociation">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转账业绩弹窗 -->
    <el-dialog title="转账业绩" :visible.sync="transferAccountsDialog" width="40%">
      <el-form label-width="120px">
        <el-form-item label="用户账号">{{ transferAccounts_data.mobile }}</el-form-item>
        <el-form-item label="转账业绩">
          <el-input v-model="transferAccounts_num"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transferAccountsDialog = false">取 消</el-button>
        <el-button type="primary" @click="sureTransferAccounts">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog title="业绩明细" :visible.sync="achievement_show" width="650px">
      <el-table :data="achievementData" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column property="mobile" label="用户账号" align="center"></el-table-column>
        <el-table-column width="100" property="today_sales_amount" label="新业绩" align="center"></el-table-column>
        <el-table-column width="100" property="all_sales_amount" label="总业绩" align="center"></el-table-column>
        <el-table-column width="100" property="today_people_num" label="新增人数" align="center"></el-table-column>
        <el-table-column width="100" property="total_people_num" label="总人数" align="center"></el-table-column>
      </el-table>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ListComponents from '@/components/listComponents';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
  components: {
    Paging,
    ListComponents,
  },
  data() {
    return {
      getDateformat,
      list: [],
      totalList: [],
      gradeList: [],
      total_number: 0,
      dialogOut: !1,
      dialogAdd: !1,
      showRmove: !1,
      showBorder: !1,
      showSelectUser: !1,
      searchForm: {
        page: 1,
        rows: 10,
        mobile: '',
        nickname: '',
        level_id: '',
        start_time: '',
        end_time: '',
        comefrom: '',
        time: '',
      },
      addForm: {
        type: 1,
        comefrom: 1,
        userImg: '',
        mobile: '',
        nickname: '',
        user_id: '',
        level_id: '',
        parent_id: '',
      },
      outForm: {
        id: '',
        status: 1,
        distribution_id: '',
        nickname: '',
        mobile: '',
        level_name: '',
      },
      comefrom: [
        {
          label: '全部',
          val: '',
        },
        {
          label: '微信',
          val: 1,
        },
        {
          label: '支付宝',
          val: 2,
        },
        {
          label: '抖音',
          val: 4,
        },
        {
          label: 'QQ',
          val: 5,
        },
        {
          label: '百度',
          val: 6,
        },
      ],
      // 显示转账弹窗
      showChangeDialog: false,
      changeMode: 1,
      details_dialog_info: {
        1: {
          title: '查看转账明细',
          money: 'integral',
        },
        2: {
          title: '查看转账释放积分明细',
          money: 'integral',
        },
        3: {
          title: '查看转账业绩明细',
          money: 'sales_amount',
        },
      },
      achievement_data_row: {},
      changeTel: '',
      change_info: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        name: '',
        amount: '',
        a_id: 0,
      },
      change_list: [],
      change_details_info_dialog: false,
      change_details_info: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
      },
      is_need_parent_data: {},
      parent_dialog: false,
      parent_id: '',
      // 转账业绩弹窗
      transferAccounts_data: {},
      transferAccountsDialog: false,
      transferAccounts_num: '',
      // 查看业绩data
      achievement_show: false,
      achievementData: [],
    };
  },
  created() {
    this.getList();
    this.getTotalList();
    this.getGradeList();
    this.getChangeList();
  },
  // 自定义指令
  directives: {
    more: {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  methods: {
    // 查看业绩明细
    lookAchievementDetails(data) {
      this.achievementData = [];
      this.$axios
        .post(this.$api.mathematical.getDistributionCommissionLog, {
          distribution_id: data.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.achievementData.push({
              all_sales_amount: res.result.all_sales_amount,
              today_people_num: res.result.today_people_num,
              today_sales_amount: res.result.today_sales_amount,
              total_people_num: res.result.total_people_num,
              mobile: data.mobile,
            });
            this.achievement_show = true;
          }
        });
    },
    // 确认执行转账业绩
    sureTransferAccounts() {
      if (!this.transferAccounts_num) {
        this.$message.warning('请填写转账业绩');
        return;
      }
      this.$axios
        .post(this.$api.mathematical.tranferIntegralSaleAmount, {
          id: this.transferAccounts_data.id,
          sales_amount: this.transferAccounts_num,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('转账成功');
            this.transferAccountsDialog = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 打开转账业绩弹窗
    transferAccountsOpenDialog(data) {
      this.transferAccounts_num = '';
      this.transferAccounts_data = data;
      this.transferAccountsDialog = true;
    },
    // 确认关联
    sureAssociation() {
      if (this.is_need_parent_data.id == this.parent_id) {
        this.$message.warning('不可关联自己');
        return;
      }
      this.$axios
        .post(this.$api.storageList.bingParentAccount, {
          distribution_id: this.is_need_parent_data.id,
          relation_id: this.parent_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('绑定成功');
            this.parent_dialog = false;
          } else {
            this.$message.success('绑定失败');
          }
        });
    },
    // 打开关联父级账号弹窗
    association(data) {
      this.is_need_parent_data = data;
      this.parent_id = '';
      this.parent_dialog = true;
    },
    sureChange() {
      if (!this.changeTel) {
        this.$message.warning('请选择手机号');
        return;
      }
      if (!this.change_info.amount) {
        this.$message.warning('请填写转账金额');
        return;
      }
      let url = this.$api.mathematical.tranferIntegralAmount;
      if (this.changeMode == 2) url = this.$api.mathematical.newTranferIntegralAmount;
      this.$axios
        .post(url, {
          amount: this.change_info.amount,
          mobile: this.changeTel,
          id: this.change_info.a_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('转账成功');
            this.showChangeDialog = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    openChangeDialog(data, mode) {
      this.change_info.amount = '';
      this.change_info.a_id = data.id;
      this.changeMode = mode;
      this.changeTel = '';
      this.showChangeDialog = true;
    },
    openDetailsDialog(mode, data) {
      this.changeMode = mode;
      this.achievement_data_row = data;
      this.getDetailsList(1);
      this.change_details_info_dialog = true;
    },
    // 服务分类下拉框 滑动加载更多数据
    loadMoreType() {
      if (Math.ceil(this.change_info.total / this.change_info.rows) > this.change_info.page) {
        this.change_info.page++;
        this.getChangeList(this.change_info.name, 1);
      }
    },
    // 获取运费列表
    getChangeList(name, is_more) {
      if (!is_more) {
        this.change_info.page = 1;
      }
      this.change_info.name = name;
      this.$axios
        .post(this.$api.distribution.list, {
          page: this.change_info.page,
          rows: this.change_info.rows,
          mobile: name,
        })
        .then(res => {
          if (res.code === 0) {
            if (is_more) {
              this.change_info.list = this.change_info.list.concat(res.result.list);
            } else {
              this.change_info.list = res.result.list;
            }
            this.change_info.total = res.result.total_number;
          }
        });
    },
    getTime(val) {
      if (val) {
        this.searchForm.start_time = val[0] / 1000;
        this.searchForm.end_time = val[1] / 1000;
      }
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        mobile: '',
        nickname: '',
        level_id: '',
        start_time: '',
        end_time: '',
        comefrom: '',
        time: '',
      };
      this.getList();
    },
    add() {
      this.dialogAdd = !0;
      this.addForm = {
        type: 1,
        comefrom: 1,
        inviteImg: '',
        userImg: '',
        mobile: '',
        nickname: '',
        user_id: '',
        level_id: '',
        parent_id: '',
      };
    },
    removeInfo() {
      this.addForm.userImg = '';
      this.addForm.mobile = '';
      this.addForm.nickname = '';
      this.addForm.user_id = '';
    },
    out(row) {
      if (!row.status) {
        this.outForm.id = row.id;
        this.outForm.nickname = row.nickname;
        this.outForm.mobile = row.mobile;
        this.outForm.level_name = row.level_name;
        this.dialogOut = !0;
      } else {
        this.$message.warning('该分销员已被清退');
      }
    },
    seeDetail(row) {
      this.$router.push({
        path: '/extension/distribution/distributorDetail',
        query: {
          id: row.user_id,
          info: JSON.stringify(row),
        },
      });
    },
    edit(row) {
      this.addForm.userImg = row.avatar;
      this.addForm.comefrom = row.comefrom;
      this.addForm.mobile = row.mobile;
      this.addForm.nickname = row.nickname;
      this.addForm.level_id = row.level_id;
      this.addForm.user_id = row.id;
      this.addForm.id = row.id;
      if (row.parent_id > 0) this.addForm.parent_id = row.parent_id;
      this.dialogAdd = !0;
    },
    goSetGrade() {
      this.$router.push({
        path: '/extension/distribution/basicSet',
        query: {
          step: '2',
        },
      });
    },
    getItem(val) {
      this.addForm.user_id = val.id;
      this.addForm.userImg = val.avatar;
      this.addForm.mobile = val.mobile;
      this.addForm.nickname = val.nickname;
      this.showSelectUser = !1;
    },
    comefromOut() {
      this.$axios
        .post(this.$api.distribution.UpStatus, {
          id: this.outForm.id,
          status: this.outForm.status,
          distribution_id: this.outForm.distribution_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.dialogOut = !1;
            this.getList();
            this.getTotalList();
            this.getGradeList();
            this.$message.success('清退成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    comefromAdd() {
      let data = {};
      if (this.addForm.id) {
        data = {
          id: this.addForm.id,
          level_id: this.addForm.level_id,
        };
      } else {
        data = {
          user_id: this.addForm.user_id,
          level_id: this.addForm.level_id,
          parent_id: this.addForm.parent_id || 0,
        };
      }
      this.$axios.post(this.addForm.id ? this.$api.distribution.edit : this.$api.distribution.add, data).then(res => {
        if (res.code == 0) {
          this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
          this.addForm = {
            type: 1,
            comefrom: 1,
            inviteImg: '',
            userImg: '',
            mobile: '',
            nickname: '',
            user_id: '',
            level_id: '',
            parent_id: '',
          };
          this.getTotalList();
          this.getList();
          this.dialogAdd = !1;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getGradeList() {
      this.$axios.post(this.$api.distribution.levelList2).then(res => {
        if (res.code == 0) {
          let list = res.result;
          this.gradeList = list;
        }
      });
    },
    getList(style) {
      if (style == 1) {
        this.searchForm.page = 1;
      }
      let data = JSON.parse(JSON.stringify(this.searchForm));
      delete data.time;
      this.$axios.post(this.$api.distribution.list, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.date = getDateformat(item.create_time);
            if (item.comefrom == 1) item.from_icon = wx;
            if (item.comefrom == 2) item.from_icon = ali;
            if (item.comefrom == 4) item.from_icon = douyin;
            if (item.comefrom == 5) item.from_icon = qq;
            if (item.comefrom == 6) item.from_icon = baidu;
          });
          this.total_number = res.result.total_number;
          this.list = list;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTotalList() {
      this.$axios
        .post(this.$api.distribution.list, {
          rows: 100000,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.totalList = list.filter(item => item.status == 0);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    detailsUpdateData(val, status) {
      if (status == 0) {
        this.change_details_info.rows = val;
      } else {
        this.change_details_info.page = val;
      }
      this.getDetailsList();
    },
    getDetailsList(style) {
      if (style) {
        this.change_details_info.page = 1;
      }
      let url = this.$api.mathematical.integralLog;
      let obj = {
        page: this.change_details_info.page,
        rows: this.change_details_info.rows,
      };
      if (this.changeMode == 1) obj.type = -111;
      if (this.changeMode == 2) obj.type = -1111;
      if (this.changeMode == 3) {
        url = this.$api.mathematical.salesAmountLog;
        obj.type = 111;
        console.log(this.achievement_data_row);
        obj.id = this.achievement_data_row.id;
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code == 0) {
          this.change_details_info.list = res.result.list;
          this.change_details_info.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    html_open_fn() {
      // const routeData = this.$router.resolve({
      //   name: '/finance/downCente', // 跳转的路由地址，用path: 'path地址'也可以
      // });
      // window.open(routeData.location.name, '_blank');
      this.$router.push('/finance/downCenter');
    },
    exportFn() {
      let that = this;
      let obj = {};
      let url = this.$api.mathematical.exportIntegral;
      if (this.changeMode == 3) {
        url = this.$api.mathematical.exportSalesAmount;
      }
      this.$axios.post(url, obj).then(res => {
        if (res.code === 0) {
          this.$alert(`<div>导出中，请耐心等待，稍后跳转到<span id="html_open" style='color:#409EFF;cursor: pointer;'>导出文件</span>中下载</div>`, '提示', {
            dangerouslyUseHTMLString: true,
          });
          this.$nextTick(() => {
            let span_btn = document.querySelectorAll('#html_open')[0];
            span_btn.removeEventListener('click', that.html_open_fn);
            span_btn.addEventListener('click', that.html_open_fn);
          });
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.el-table {
  .userInfo {
    display: flex;
    align-items: center;

    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.add-img {
  position: relative;
  border-radius: 5px;
  width: 100px;
  height: 100px;

  .img {
    width: 100%;
    height: 100%;
    border: 1px solid #c0ccda;
    border-radius: 5px;
  }

  .el-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .select {
    width: 100%;
    height: 100%;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 5px;
    cursor: pointer;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 28px;
      color: #8c939d;
    }
  }

  .select-active {
    border-color: #409eff;
  }

  .remove {
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: #fff;
    opacity: 1;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    z-index: 2;
  }
}

.out-info {
  color: #409eff;

  span {
    margin-right: 10px;
  }
}
</style>
